<template>
  <div class="Santilena">
    <h1><a href="https://santilena.com.br">Por que a Santilena - Produtos Eletrônicos?</a></h1>
    <p>
      Fornecendo produtos de qualidades a mais de 10 anos, buscando entregar sempre as melhores tecnologias para os nossos clientes e parceiros!
    </p>
    <p>
      <a href="https://santilena.com.br">Site Oficial, Acesse Aqui</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'ParceiroSantilena',
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
